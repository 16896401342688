import * as React from 'react';
import LearningResource from './LearningResource';

export default ({ categoryId }) => {
  const [entries, setEntries] = React.useState([]);
  const [next, setNext] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchEntries = (endpoint) => {
    setLoading(true);

    fetch(endpoint)
      .then((res) => res.json())
      .then((json) => {
        setNext(json.paginate.links.next);
        setEntries([...entries, ...json.entries]);
        setLoading(false);
      });
  };

  const onLoadMore = () => {
    fetchEntries(next);
  };

  React.useEffect(() => {
    fetchEntries(`/api/v1/category/${categoryId}.json`);
  }, []);

  return (
    <>
      {entries.map((entry, index) => <LearningResource key={entry.id} entry={entry} even={index % 2 === 0}/>)}
      <footer className="section-more">
        {next && <button disabled={!next} onClick={onLoadMore}>
          {!loading && <span className="text">Load more</span>}
          {loading &&
          <div className="ldr">
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
          }
        </button>
        }
      </footer>
    </>
  );
}
