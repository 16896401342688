import * as React from 'react';
import * as classNames from 'classnames';

export default ({ entry, even }) => {
  const {
    title,
    logo,
    description,
    homepage,
    copyright,
    showScore,
    rating,
    scores,
    hotlinks,
    featured,
  } = entry;

  const {
    effectiveness,
    design,
    engagement,
    reputation,
  } = scores;

  const accordion = React.useRef(null);
  const slider = React.useRef(null);

  React.useEffect(() => {
     if (accordion.current) {
       new window.Accordion(accordion.current);
     }
  }, [accordion]);

  return (
    <article className="accordion" data-accordion ref={accordion} className={classNames({'entry-even': even})}>
      <header className="alt" data-control>
        <div className="viewport">
          <div className="logo-img">
            <img src={logo.url} alt={logo.filename}/>
          </div>
          <div className="content">
            {title}
          </div>
          <div className="menubox rating">
            {rating}
          </div>
          <div className="menubox expander">
            <svg className="icon icon-arrow-down">
              <use xlinkHref="#icon-arrow-down"/>
            </svg>
          </div>
        </div>
      </header>
      <div className="header-link alt viewport">
        <a href={homepage.href} target={homepage.target} className="menubox hide-label">
          <svg className="icon icon-link">
            <use xlinkHref="#icon-link"/>
          </svg>
          <span className="label">{homepage.label}</span>
        </a>
      </div>
      <div data-content>
        <section>
          <div className="viewport">
            <p className="logo aligntop">
              <svg className="icon icon-info">
                <use xlinkHref="#icon-info"/>
              </svg>
            </p>
            <p className="content">
              {description}
            </p>
          </div>
        </section>
        {showScore &&
        <section>
          <div className="viewport">
            <p className="logo">
              <svg className="icon icon-ratings">
                <use xlinkHref="#icon-ratings"/>
              </svg>
            </p>
            <p className="content">
              Effectiveness: <b>{effectiveness}</b> &nbsp; &nbsp;
              Design: <b>{design}</b> &nbsp;  &nbsp;
              Engagement: <b>{engagement}</b> &nbsp;  &nbsp;
              Reputation: <b>{reputation}</b>
            </p>
          </div>
        </section>
        }
        <section>
          <div className="viewport">
            <p className="logo"/>
            <p className="content">
              <a href={homepage.href} target={homepage.target}>
                <svg className="icon icon-link">
                  <use xlinkHref="#icon-link"/>
                </svg>
                <span className="label">{homepage.label}</span>
              </a>
            </p>
          </div>
        </section>
        {hotlinks.length > 0 &&
        <section>
          {hotlinks.map((hotlink, index) =>
            <div key={index} className="viewport">
              <p className="logo"/>
              <p className="content">
                <a href={hotlink.href} target={hotlink.target}>
                  <svg className="icon icon-link">
                    <use xlinkHref="#icon-link"/>
                  </svg>
                  <span className="label">{hotlink.label}</span>
                </a>
              </p>
            </div>
          )}
        </section>
        }
        {copyright &&
        <section>
          <div className="viewport">
            <p className="logo">
              <svg className="icon icon-copyright">
                <use xlinkHref="#icon-copyright"/>
              </svg>
            </p>
            <p className="content">
              {copyright}
            </p>
          </div>
        </section>
        }
        {featured.length > 0 &&
        <section className="preview">
          <div className="viewport">
            <p className="logo">
              <svg className="icon icon-eye">
                <use xlinkHref="#icon-eye"/>
              </svg>
            </p>
            <p className="content">
              Featured content
            </p>
          </div>
          <div className="viewport slider" ref={slider}>
            {featured.map((feature, index) =>
              <a key={index} href={feature.link.href} className={classNames({'d-none': index >= 1})}>
                <figure>
                  {feature.image &&
                  <img src={feature.image.url} alt={feature.image.filename}/>
                  }
                  <figcaption>
                    <span className="iconbox">
                        <svg className="icon icon-link">
                          <use xlinkHref="#icon-link"/>
                        </svg>
                    </span>
                    {feature.link.label}
                  </figcaption>
                </figure>
              </a>
            )}
          </div>
        </section>
        }
      </div>
    </article>
  )
};
